/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React, { useEffect, useRef } from 'react';
import {
    bool, object, shape, string,
} from 'prop-types';
import { useDispatch } from 'react-redux';
import ResponsiveImage from '../../../GraphqlCommonComponents/ResponsiveImage/ResponsiveImage';
import { trackEvent } from '../../../../../../state/ducks/TagManager/ducks/TagManager/TagManager-Actions';

const VideoComponent = ({
    videoUrl, contentType, classes, videoBlock, previewImage, videoClass, productBaseCode, isBRY, isMuted,
}) => {
    const videoRef = useRef();

    const dispatch = useDispatch();
    const trackEventObj = {
        eventCategory: 'product video',
        eventLabel: `${productBaseCode} | ${videoUrl}`,
    };

    const track = (eventActions) => {
        dispatch(trackEvent({
            ...trackEventObj,
            eventAction: eventActions,
        }));
    };

    const onPlayEvent = () => {
        track(
            'start',
        );
    };

    const onPauseEvent = (e) => {
        if (e.target.currentTime !== e.target.duration) {
            track(
                'pause',
            );
        }
    };
    let videoTenPerc = false; let videoTwentyFivePerc = false; let videoFiftyPerc = false; let videoSeventyFivePerc = false;
    const onSeeking = (e) => {
        videoTenPerc = false;
        videoTwentyFivePerc = false;
        videoFiftyPerc = false;
        videoSeventyFivePerc = false;
        if (e.target?.currentTime  !== 0) {
            track(
                'seeking',
            );
        }
    };

    const onEnded = () => {
        videoTenPerc = false;
        videoTwentyFivePerc = false;
        videoFiftyPerc = false;
        videoSeventyFivePerc = false;
        track(
            'completed',
        );
    };

    useEffect(() => {
        // chrome, safari, opera, edge
        // event: webkitfullscreenchange
        // dom-api: webkitFullscreenElement

        // firefox
        // event: mozfullscreenchange [or] fullscreenchange
        // dom-api: mozFullScreenElement

        // ie
        // event: msfullscreenchange
        // dom-api: msFullscreenElement

        const eventDomApiMap = {
            webkitfullscreenchange: 'webkitFullscreenElement',
            mozfullscreenchange: 'mozFullScreenElement',
            msfullscreenchange: 'msFullscreenElement',
            fullscreenchange: 'mozFullScreenElement',
        };

        const videoFullScreenTrackEvent = (event) => {
            const fullscreenApi = eventDomApiMap[event.type];
            const isFullscreen = document[fullscreenApi] !== null;

            if (isFullscreen) {
                track('fullscreen');
            } else {
                track('exit fullscreen');
            }
        };

        if (videoRef && videoRef.current) {
            ['fullscreenchange', 'webkitfullscreenchange', 'mozfullscreenchange', 'msfullscreenchange'].forEach((event) => videoRef.current.addEventListener(event, videoFullScreenTrackEvent, false));
        }
    });

    const onVolumeChange = () => {
        if (typeof document !== 'undefined' && document.getElementById('productVideo')) {
            const video =   document.getElementById('productVideo');
            if (video.muted) {
                track(
                    'mute',
                );
            } else {
                track(
                    'volume change',
                );
            }
        }
    };
    const onWaiting = () => {
        track(
            'buffering',
        );
    };

    const onTimeUpdate = (e) => {
        if (e.target?.duration && e.target?.currentTime) {
            const  perc = ((e.target.currentTime /  e.target.duration) * 100).toFixed(2);
            if (!videoTenPerc && Math.floor(perc) === 10) {
                videoTenPerc = true;
                track(
                    '10% progress',
                );
            } else if (!videoTwentyFivePerc && Math.floor(perc) === 25) {
                videoTwentyFivePerc = true;
                track(
                    '25% progress',
                );
            } else if (!videoFiftyPerc && Math.floor(perc) === 50) {
                videoFiftyPerc =  true;
                track(
                    '50% progress',
                );
            }  else if (!videoSeventyFivePerc && Math.floor(perc) === 75) {
                videoSeventyFivePerc = true;
                track(
                    '75% progress',
                );
            }
        }
    };
    if (contentType === 'video/mp4') {
        return  (
            <video
                data-testid="hero-product-video"
                id="productVideo"
                controls
                poster={previewImage}
                className={videoClass}
                onPlay={() => onPlayEvent()}
                onPause={(e) => onPauseEvent(e)}
                onSeeking={(e) => onSeeking(e)}
                onEnded={() => onEnded()}
                onVolumeChange={() => onVolumeChange()}
                onWaiting={() => onWaiting()}
                onTimeUpdate={(e) => onTimeUpdate(e)}
                autoPlay={isBRY}
                loop
                muted={isMuted}
                ref={videoRef}
                playsinline="playsinline"
            >
                <source src={videoUrl} type="video/mp4" preload="metadata" />
                <track kind="captions" />
            </video>
        );
    }

    return (
        <ResponsiveImage
            className={classes.productImage}
            path={videoUrl}
            alt={videoBlock?.video?.title}
            params={{
                desktop: 'width=545&height=597',
                tablet: 'width=406&height=445quality=100',
            }}
            breakpoints={{
                desktop: '1024px',
                tablet: '640px',
            }}
        />
    );
};
VideoComponent.propTypes = {
    classes: object.isRequired,
    videoUrl: string.isRequired,
    videoClass: string.isRequired,
    previewImage: string,
    productBaseCode: string,
    contentType: string,
    videoBlock: shape({
        video: shape({
            title: string,
        }),
    }),
    isBRY: bool,
    isMuted: bool,
};
VideoComponent.defaultProps = {
    videoBlock: {},
    previewImage: '',
    contentType: '',
    productBaseCode: '',
    isBRY: false,
    isMuted: false,
};
export default (VideoComponent);
