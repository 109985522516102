/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
    object, string, bool, shape, number, func,
} from 'prop-types';
import { Link, useLocation } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import generateValidCss from '../../../../helpers/contentstack/generateValidCss';
import Copy from '../../Copy';
import { ifExternalUrl } from '../helper';
import { trackEvent } from '../../../../../state/ducks/TagManager/ducks/TagManager/TagManager-Actions';
import parseStyles from '../../../../helpers/contentstack/parseStyles';
import { getFeatureFlags, getPresentationFamily } from '../../../../../state/ducks/App/ducks/Config/Config-Selectors';
import { openPassportSideBar } from '../../../../../state/ducks/Passport/Passport-Action';
import NupImageVideo from './NupImageVideo';
import LinkOrATag from '../../../GraphqlComponents/GraphqlCommonComponents/LinkOrATag/LinkOrATag';

const useStyles = makeStyles((theme) => ({
    nUp: (data) => ({
        maxWidth: '1400px',
        position: 'relative',
        padding: data?.reference?.[0].presentation_style === 'Showcase Container' ? '10px 10px calc(280px + 4vw)' : '0 10px 0',
        backgroundColor: (data.reference?.[0]?.background?.add_background) ? data.reference[0]?.background?.solid_background_color : 'none',
        '@media screen and (max-width: 624px)': {
            paddingBottom: data?.reference?.[0].presentation_style === 'Showcase Container' ? 'calc(420px + 4vw)' : '0',
        },
    }),
    nUpLess: {
        maxWidth: '1400px',
        overflow: 'hidden',
        position: 'relative',

    },
    panelGroupBottomMarginDelete: {
        '& a:last-child': {
            marginBottom: '0',
        },
        '& a:nth-last-child(2)': {
            marginBottom: '0',
        },
    },
    categoryPer3row: {
        maxHeight: '700px',
    },
    categoryPer4row: {
        maxHeight: '900px',
    },
    header: {
        paddingTop: '1px',
        paddingBottom: '15px',
        fontSize: '1rem',
        lineHeight: '1.1em',
        fontWeight: '800',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: theme.palette.sectionHeaderTextColor || '#202020',
        '& p,h1,h2,h3,h5,h6': {
            margin: '.75em 0 .5em',
        },
        '& $h2': {
            fontSize: '18px',
            fontWeight: 'normal',
            fontFamily: 'loretta-variable, PlayfairDisplayRegular, serif',
        },
        '& img': {
            height: '25px',
            width: '25px',
            marginRight: '10px',
        },
    },
    heading_paragraph_wrapper: {
        width: '100%',
        marginTop: '15px',
        marginBottom: '30px',
    },
    heading_paragraph: {
        margin: '0 auto',
        textAlign: 'center',
        width: '95%',
        lineHeight: '1.5em',
        letterSpacing: '.4px',
        fontSize: '1em',
        '& h1,h2,h3,h4,h5,h6,p': {
            margin: '0px 0 15px 0',
            fontSize: 'inherit',
            fontWeight: 'inherit',
        },
    },
    panel: (data) => ({
        width: (data?.reference?.[0].row_count_mobile && data?.reference?.[0].mobile_display_count) ? `${Math.floor((data?.reference?.[0].row_count_mobile / data?.reference?.[0].mobile_display_count) * 100)}%` : 'auto',
        height: 'auto',
        marginBottom: '10px',
        textDecoration: 'none',
        fontSize: '0.9rem',
        backgroundSize: 'cover',
        textAlign: 'center',
        color: theme.palette.black,
        '@media screen and (min-width: 625px)': {
            width: '33.33%',
        },
        '&:last-of-type': {
            marginBottom: '16px',
        },
        '& .messageCopy, & span, & div': {
            margin: 'auto !important',
        },
        '& img': {
            maxWidth: '100%',
            padding: '0px 20px 10px 20px !important',
            margin: 'auto !important',
        },
    }),
    panelOneItem: {
        width: '100%',
        height: 'auto',
        maxWidth: '362px',
        textDecoration: 'none',
        fontSize: '0.9rem',
        backgroundSize: 'cover',
        textAlign: 'center',
        color: theme.palette.black,
        '&:nth-of-type(1)': {
            marginTop: '0',
        },
        padding: '20px 0 5px',
        border: theme.palette.gfMobileInputBorderBottom || '1px solid #cccccc',
    },
    panelOneItemBorderless: {
        width: '100%',
        height: 'auto',
        maxWidth: '362px',
        textDecoration: 'none',
        fontSize: '0.9rem',
        backgroundSize: 'cover',
        textAlign: 'center',
        color: theme.palette.black,
        margin: '4px 0 16px',
        '&:nth-of-type(1)': {
            marginTop: '0',
        },
    },
    panelTwoItem: {
        marginBottom: '0',
        '&+a': {
            marginBottom: '0 !important',
        },
    },
    panelThreeItem: {
        width: '33.33%',
        height: 'auto',
        textDecoration: 'none',
        fontSize: '0.9rem',
        backgroundSize: 'cover',
        textAlign: 'center',
        color: theme.palette.black,
        marginTop: '20px',
        padding: '5px',
    },
    panelGroup: {
        padding: '0',
        display: 'flex',
        flexWrap: 'wrap',
        flexFlow: 'row wrap',
        alignItems: 'flex-start',
        alignContent: 'flex-end',
        flexDirection: 'row',
        justifyContent: 'center',
        [theme.breakpoints.down(325)]: {
            '& span > div': {
                fontSize: '1em !important',
            },
        },
    },
    divider: (data) => ({
        backgroundColor: data.reference[0].vertical_entry_dividers?.divider_color || '#ccc',
        width: '100%',
        height: data.reference[0].vertical_entry_dividers?.width || '1px',
    }),
    root: {
        padding: theme.spacing(3, 0, 0, 0),
    },
    containerLink: {
        textDecoration: 'none',
    },
    cta: {
        boxSizing: 'border-box',
        display: 'block',
        margin: '0 auto',
        fontSize: '18px',
        fontWeight: '800',
        borderRadius: '2px',
    },
    circleMask: {
        borderRadius: '50%',
    },
    Standard: theme.palette.nup?.Standard,
    StandardMobile: {
        width: '99%',
        height: 'auto',
    },
    Medium: theme.palette.nup?.Medium,
    Small: theme.palette.nup?.Small,
    viewMoreWrapper: {
        width: 'calc(100vw)',
        bottom: '0',
        height: '40px',
        display: 'block',
        backgroundColor: 'rgba(255,255,255,0)',
    },
    viewMoreGradientWrapper: {
        left: '0',
        width: 'calc(100vw)',
        bottom: '0',
        height: '200px',
        display: 'block',
        position: 'absolute',
        backgroundColor: 'rgba(255,255,255,0)',
        backgroundImage: 'linear-gradient(to bottom, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, .9) 60%)',
    },
    viewMore: {
        left: 'calc(50% - 0px)',
        color: theme.palette.common?.white,
        width: 'calc(35vw)',
        transform: 'translate(-50%, 0)',
        fontSize: '16px',
        boxShadow: '0 16px 16px -14px rgba(0, 0, 0, .7)',
        fontWeight: '700',
        borderRadius: '5px',
        backgroundColor: theme.palette.text?.primary,
        lineHeight: '1.75',
        fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
        textTransform: 'uppercase',
        border: '0',
        cursor: 'pointer',
        outline: 'none',
        position: 'absolute',
        bottom: '24px',
        userSelect: 'none',
    },
    viewLess: {
        bottom: '5px',
    },
    NupSciSlider: {
        '& $header': {
            paddingBottom: '10px',
            '& $h2': {
                fontSize: '18px',
                marginBottom: 'inherit',
                fontFamily: 'loretta-variable, PlayfairDisplayRegular, serif',
                fontWeight: 'normal',
            },
        },
        '& $panelGroup': {
            width: '100%',
            transform: 'translateZ(0)',
            overflowY: 'auto',
            flexWrap: 'nowrap',
            justifyContent: 'flex-start',
        },
        '& $panel': {
            fontSize: '12px',
            margin: '0 3.5%',
        },
        '& $imageContainer': {
            '& $img': {
                width: '77px',
                height: '80px',
                clipPath: 'ellipse(36px 36px at 38px 41px)',
                maxWidth: 'inherit',
            },
        },
    },
    showcaseHeadingFirstColor: (data) => ({
        color: data.reference[0].showcase_header_options?.heading_color_1?.color || '#2f2f2f',
    }),
    showcaseHeadingSecondColor: (data) => ({
        color: data.reference[0].showcase_header_options?.heading_color_2?.color || '#2f2f2f',
    }),
    videoBlock: {
        marginBottom: 10,
    },
    productVideo: {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
    },
    panelHidden: {
        '& $panel': {
            '& $imageContainer': {
                '& img': {
                    display: 'none',
                },
            },
            '& $messageContainer': {
                display: 'none',
            },
        },
    },
    nupMobileImage: {
        height: 'auto',
    },
    nupContactUs: {
        '& $panelOneItem': {
            border: 'inherit',
            maxWidth: '100%',
            display: 'block',
            marginBottom: '15px',
            padding: '0px',
        },
    },
}));

const NUpMobile = ({
    data, isBot, track, featureFlags, passportOpenSideBar, pageView, presentationFamily,
}) => {
    const classes = useStyles(data);
    const location = useLocation();
    const contactUs = location?.pathname?.includes('contact-us');
    const [showLess, toggleViewMore] = React.useState(true);
    const onToggleViewMore = () => toggleViewMore(!showLess);
    const flowerBrand = presentationFamily === 'flower';
    let panelClass = 'panel';
    if (!data?.reference?.[0]) return <></>;
    if (data?.reference?.[0]) {
        const banner = data.reference[0];
        const hasDividers = banner.vertical_entry_dividers?.add_vertical_dividers;
        const showDividers = hasDividers && banner.mobile_row_item_count === 1;
        const subSiloImoc = flowerBrand && pageView?.pageType === 'category';
        let SCISiloStyle = {};
        if (!pageView?.pageType) {
            SCISiloStyle = classes.panelHidden;
        } else if (subSiloImoc) {
            SCISiloStyle = classes.NupSciSlider;
        }

        if (banner.mobile_row_item_count === 1) {
            panelClass = (!banner.hide_panel_borders_on_mobile) ? 'panelOneItem' : 'panelOneItemBorderless';
        } else if (banner.reference.length === 3) {
            panelClass = 'panelThreeItem';
        }
        const getContainerBackgroundStyles = () => {
            if (!banner.background?.add_background) return {};
            const bgVal = banner.background;
            const backgroundStyles = {
                backgroundColor: bgVal?.solid_background_color.color,
            };
            if (bgVal?.images) {
                backgroundStyles.backgroundImage = `url("${bgVal?.images?.url}")`;
                backgroundStyles.backgroundSize = 'cover';
            }
            return backgroundStyles;
        };
        const loadCssRowBase = ((banner.styles?.per_row || banner?.mobile_row_item_count) === 3 ? classes.categoryPer3row : classes.categoryPer4row);
        const handleClick = (e, title) => {
            if (title === 'Celebrations Passport'
                // VARIANT A is "Passport Enrolment Page"
                // CONTROL is "Passport Side Bar"
                && featureFlags['which-passport-sidebar-enabled-variant']?.variant === 'CONTROL') {
                e.preventDefault();
                passportOpenSideBar();
            }
        };

        const renderHeading = (nupData) => {
            /*
             * @description handles conditional header rendering and avoids nested ternaries in markup.
             * @param {object} nup data
             * @return heading markup for eithe standard or t3 showcase heading
            */

            const showcaseHeader = (nup) => {
                /*
                 * @description handles params and markup for larger showcase nup header as designed by t3.
                 * @param {object} header data
                 * @return markup with 2 font colors as described in classes in this component.
                */
                const headingWords = nup.heading.split(' ');
                let headingWithColor1 = nup.heading;
                let headingWithColor2 = '';
                if (nup.showcase_header_options?.word_count_for_color_1) {
                    headingWithColor1 = headingWords.slice(0, nup.showcase_header_options?.word_count_for_color_1).join(' ');
                    headingWithColor2 = headingWords.slice(nup.showcase_header_options?.word_count_for_color_1).join(' ');
                }
                return (
                    <Typography
                        variant={`h${nup.heading_level || 2}`}
                        className={`${classes.header} ${banner.heading_styles?.background_stroke && classes.background_stroke}`}
                        style={parseStyles(banner.heading_styles?.font_styles)}
                    >
                        <span className={classes.showcaseHeadingFirstColor}>{headingWithColor1}</span>&nbsp;
                        <span className={classes.showcaseHeadingSecondColor}>{headingWithColor2}</span>
                    </Typography>
                );
            };

            if (nupData.heading && banner.presentation_style !== 'Showcase Container') {
                return (
                    <div
                        className={`${classes.header} ${nupData.heading_styles?.background_stroke && classes.background_stroke}`}
                        style={parseStyles(nupData.heading_styles?.font_styles)}
                        data-testid={nupData?.presentation_style !== 'Heading With Logo Image' ? 'heading-font-styles' : ''}
                    >
                        {nupData?.heading_logo_image?.url && nupData?.presentation_style === 'Heading With Logo Image' && <img src={nupData?.heading_logo_image?.url} alt={nupData?.heading_logo_image?.title} />}
                        <ReactMarkdown source={nupData.heading} />
                    </div>
                );
            }
            if (nupData.presentation_style === 'Showcase Container') {
                return showcaseHeader(nupData);
            }
            return null;
        };

        return (
            <div
                className={`
                ${banner.reference.length > 3 ? classes.panelGroupBottomMarginDelete : ''}
                ${(showLess && banner.reference.length > 6 && !banner.disable_view_more) ? `${classes.nUpLess} ${loadCssRowBase}` : classes.nUp}
                ${contactUs ? classes.nupContactUs : ''}
                ${SCISiloStyle}`}
                style={getContainerBackgroundStyles()}
            >
                { renderHeading(banner) }
                {
                    banner.heading_paragraph ? (
                        <div className={classes.heading_paragraph_wrapper}>
                            <div className={classes.heading_paragraph}>
                                <ReactMarkdown source={banner.heading_paragraph} />
                            </div>
                        </div>
                    ) : null
                }
                <div className={classes.panelGroup}>
                    {banner?.reference.map((panelItem, index, array) => {
                        const renderImageVideoCopy = () => (
                            <div style={generateValidCss(panelItem?.message_styling, true)} className={classes.generalMessageStylingClass} data-testid="nup-panel-msg">
                                <div className={classes[banner.presentation_style]} style={generateValidCss(panelItem?.container_attributes, true)}>
                                    <Copy data={panelItem?.message_collection} />
                                </div>
                            </div>
                        );
                        return (
                            <>
                                {panelItem?.image_mobile?.content_type === 'video/mp4' ? (
                                    <>
                                        {panelItem?.image_mobile ? (
                                            <>
                                                {banner.nup_disable_linking ? (
                                                    renderImageVideoCopy()
                                                ) : (
                                                    <>
                                                        <NupImageVideo panelItem={panelItem} isBot={isBot} banner={banner} location={location} subSiloImoc={subSiloImoc} noRenderCopy />
                                                        <LinkOrATag
                                                            indexKey={panelItem?.linking?.link?.href}
                                                            tabIndex="-1"
                                                            href={panelItem?.linking?.link?.href}
                                                            title={panelItem?.linking?.link?.title}
                                                            isTrackEventEnabled
                                                            dataTest="nup-panel"
                                                            className={`${classes[panelClass]} ${banner?.reference?.length === 2 && classes.panelTwoItem}`}
                                                            trackingEventCategory={panelItem.linking?.tracking_event_category || ''}
                                                            trackingEventAction={panelItem.linking?.tracking_event_action || ''}
                                                            trackingEventLabel={panelItem.linking?.tracking_event_label || ''}
                                                            handleCallback={handleClick}
                                                        >

                                                            <NupImageVideo panelItem={panelItem} isBot={isBot} banner={banner} location={location} subSiloImoc={subSiloImoc} noRenderImage />
                                                        </LinkOrATag>
                                                    </>
                                                )}
                                            </>
                                        ) : (
                                            renderImageVideoCopy()
                                        )}
                                    </>
                                ) : (
                                    <>
                                        {banner.nup_disable_linking ? (
                                            <div className={`${classes[panelClass]} ${banner?.reference?.length === 2 && classes.panelTwoItem}`}>
                                                {panelItem?.image_mobile
                                                /* If a backgroud image is provided, it will render as default product tile
                                           else it will render a div of provided dimension / presentation style */
                                                    ? (
                                                        <NupImageVideo panelItem={panelItem} isBot={isBot} banner={banner} location={location} subSiloImoc={subSiloImoc} />
                                                    /* Added Copy components instead of manual switch case
                                           to avoid code repetition and to add more functionality to n-up panel.
                                           We can use Panel component directly but background image logic has not been implemented in it yet */
                                                    ) : (
                                                        renderImageVideoCopy()
                                                    )}
                                            </div>
                                        ) : (
                                            <LinkOrATag
                                                indexKey={panelItem?.linking?.link?.href}
                                                tabIndex="-1"
                                                href={panelItem?.linking?.link?.href}
                                                title={panelItem?.linking?.link?.title}
                                                isTrackEventEnabled
                                                dataTest="nup-panel"
                                                className={`${classes[panelClass]} ${banner?.reference?.length === 2 && classes.panelTwoItem}`}
                                                trackingEventCategory={panelItem.linking?.tracking_event_category || ''}
                                                trackingEventAction={panelItem.linking?.tracking_event_action || ''}
                                                trackingEventLabel={panelItem.linking?.tracking_event_label || ''}
                                                handleCallback={handleClick}
                                            >

                                                {panelItem?.image_mobile
                                                /* If a backgroud image is provided, it will render as default product tile
                                             else it will render a div of provided dimension / presentation style */
                                                    ? (
                                                        <NupImageVideo panelItem={panelItem} isBot={isBot} banner={banner} location={location} subSiloImoc={subSiloImoc} />
                                                    /* Added Copy components instead of manual switch case
                                             to avoid code repetition and to add more functionality to n-up panel.
                                             We can use Panel component directly but background image logic has not been implemented in it yet */
                                                    ) : (
                                                        renderImageVideoCopy()
                                                    )}
                                            </LinkOrATag>
                                        )}
                                    </>
                                )}
                                {showDividers && index < array.length - 1 && <div className={classes.divider} data-testid="panel-divider" />}
                            </>
                        );
                    })}
                </div>
                {banner.reference?.length > 6 && !banner.disable_view_more && (
                    <div className={showLess ? classes.viewMoreGradientWrapper : classes.viewMoreWrapper}>
                        <button type="button" className={`${classes.viewMore} ${!showLess ? classes.viewLess : ''}`} onClick={onToggleViewMore}>
                            {showLess ? <span>View More</span> : <span>View Less</span>}
                        </button>
                    </div>
                )}
            </div>
        );
    }
    return <></>;
};

NUpMobile.propTypes = {
    isBot: bool,
    data: shape({
        disable_view_more: bool,
        display_on: string,
        full_width: bool,
        heading: string,
        heading_styles: object,
        row_count_mobile: number,
        mobile_display_count: number,
        mobile_row_item_count: number,
        presentation_style: string,
        row_count: number,
        title: string,
        unique_selector: shape({ selector_id: string }),
    }).isRequired,
    track: func.isRequired,
    featureFlags: shape({
        'which-passport-sidebar-enabled-variant': shape({
            variant: string,
        }),
    }),
    passportOpenSideBar: func,
    presentationFamily: string.isRequired,
    pageView: shape({
        pageType: string,
    }).isRequired,
};

NUpMobile.defaultProps = {
    isBot: false,
    featureFlags: {},
    passportOpenSideBar: () => {},
};

const mapStateToProps = (state) => ({
    featureFlags: getFeatureFlags(state),
    presentationFamily: getPresentationFamily(state),
});
const mapDispatchToProps = (dispatch) => ({
    track: bindActionCreators(trackEvent, dispatch),
    passportOpenSideBar: bindActionCreators(openPassportSideBar, dispatch),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(NUpMobile);
