/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import {
    bool,
    object,
} from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { useLocation } from 'react-router-dom';
import generateValidCss from '../../../../helpers/contentstack/generateValidCss';
import Copy from '../../Copy';
import ResponsiveImage from '../../../GraphqlComponents/GraphqlCommonComponents/ResponsiveImage/ResponsiveImage';
import VideoComponent from '../../../GraphqlComponents/GraphqlProductPage/Partials/ProductImageUI/VideoComponent';

const useStyles = makeStyles((theme) => ({
    imageContainer: {
        height: '90%',
        width: '100%',
        '& img': {
            width: '100%',
            height: 'auto',
            marginBottom: '5px',
            maxWidth: '100%',
            objectFit: 'contain',
            '&:after': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                left: 0,
                height: '100%',
                width: '100%',
                background: 'linear-gradient(115.42deg, rgba(0, 0, 0, 0.6) 13.4%, rgba(0, 0, 0, 0.375) 42.44%, rgba(0, 0, 0, 0) 68.26%)',
            },
        },
    },
    gradientImageContainer: {
        height: '100%',
        width: '95%',
        position: 'relative',
        '& img': {
            height: 200,
            maxWidth: '95%',
            objectFit: 'contain',
        },
        '& picture:after': {
            content: '""',
            display: 'block',
            position: 'absolute',
            top: 0,
            left: 3,
            height: '97%',
            width: '100%',
            background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.6) 13.4%, rgba(0, 0, 0, 0.375) 42.44%, rgba(0, 0, 0, 0) 68.26%)',
        },
    },
    gradientMessageContainer: {
        padding: '0 3%',
        position: 'absolute',
        bottom: 25,
        boxSizing: 'border-box',
        textAlign: 'center',
        width: '48%',
        color: theme.palette.white,
        fontFamily: 'LatoBold',
        fontWeight: 900,
        fontSize: '1.2em',
    },
    imageContainerOneItem: {
        height: '100%',
        '& img': {
            width: '100%',
            paddingBottom: '5px',
            objectFit: 'contain',
            height: 'auto',
        },
    },
    messageContainer: {
        boxSizing: 'border-box',
        padding: '0 2%',
        position: 'relative',
        marginTop: '-10px',
    },
    messageContainerOneItem: {
        boxSizing: 'border-box',
        position: 'relative',
        marginTop: '-10px',
    },

}));

const NupImageVideo = ({
    panelItem, isBot, banner, subSiloImoc, noRenderCopy, noRenderImage,
}) => {
    const classes = useStyles();
    const location = useLocation();
    let imageContainerClass = 'imageContainer';
    let messageContainerClass = 'messageContainer';

    if (banner.gradient_overlay === true) {
        imageContainerClass = 'gradientImageContainer';
        messageContainerClass = 'gradientMessageContainer';
    }
    if (banner.mobile_row_item_count === 1) {
        imageContainerClass = 'imageContainerOneItem';
        messageContainerClass = 'messageContainerOneItem';
    }

    const renderImage = () => {
        const video = panelItem?.image_mobile?.content_type === 'video/mp4';
        const isLocalGiftsPage = location?.pathname === '/wanderers-lp' || location?.pathname === '/local-gifts';
        if (isBot) {
            return  <img src={panelItem?.image_mobile?.url} alt={panelItem?.image_mobile?.title} />;
        }
        if (video) {
            return (
                <div className={classes.videoBlock}>
                    <VideoComponent
                        isBRY={!!isLocalGiftsPage}
                        isMuted={!!isLocalGiftsPage}
                        classes={classes}
                        videoUrl={panelItem?.image_mobile?.url || ''}
                        videoClass={classes.productVideo}
                        contentType={panelItem?.image_mobile?.content_type || ''}
                    />
                </div>
            );
        }
        if (!panelItem.image_mobile) {
            return   (
                <ResponsiveImage
                    params={{}}
                    path={panelItem.image.url}
                    alt={panelItem.image.description || 'Background Image'}
                    className={`${(panelItem.container_attributes?.[0]?.circle_mask?.mask ? classes.circleMask : '')} ${(banner.presentation_style ? classes[banner.presentation_style] : '')}`}
                    style={!subSiloImoc ? { ...(generateValidCss(panelItem?.container_attributes, true) || {}) } : {}}
                    dimensions={panelItem.image.dimension}
                />
            );
        }
        return    (
            <ResponsiveImage
                params={{}}
                path={panelItem.image_mobile.url}
                alt={panelItem.image_mobile.title}
                className={`${(panelItem.container_attributes?.[0]?.circle_mask?.mask ? classes.circleMask : '')} ${(banner.presentation_style ? classes[banner.presentation_style] : '')} ${classes.nupMobileImage}`}
                style={!subSiloImoc ? { ...(generateValidCss(panelItem?.container_attributes, true) || {}) } : {}}
                dimensions={panelItem.image_mobile.dimension}
            />
        );
    };
    return (
        < >
            {!noRenderImage && (
                <div className={classes[imageContainerClass]}>
                    {renderImage()}
                </div>
            )}
            {!noRenderCopy && (
                <div className={classes[messageContainerClass]} style={generateValidCss(panelItem?.message_styling, true)} data-testid="nup-panel-msg">
                    <Copy data={panelItem?.message_collection} />
                </div>
            )}
        </>
    );
};

NupImageVideo.propTypes = {
    isBot: bool,
    noRenderCopy: bool,
    noRenderImage: bool,
    banner: object,
    panelItem: object,
    subSiloImoc: bool,
};

NupImageVideo.defaultProps = {
    isBot: false,
    noRenderCopy: false,
    noRenderImage: false,
    banner: {},
    panelItem: {},
    subSiloImoc: false,
};

export default NupImageVideo;
